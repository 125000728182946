<template>
  <div class="faq-contacts">
    <div class="faq-contacts__content">
      <span class="faq-contacts__title">
        Если вы не нашли ответа на свой вопрос, задайте его нам по телефону:
      </span>
      <a :href="('tel:' + phone) | formatNumber">
        <span class="faq-contacts__subtitle">{{ phone }}</span>
      </a>
    </div>
    <div class="faq-contacts__content">
      <span class="faq-contacts__title">или напишите на email:</span>
      <a :href="'mailto:' + email">
        <span class="faq-contacts__subtitle">{{ email }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqContactsComponent",
  data() {
    return {};
  },
  props: {
    phone: String,
    email: String,
  },
};
</script>

<style lang="stylus">
.faq-contacts {
  width 100%
  max-width 413px
  height 100%
  max-height 256px
  padding 30px
  border-radius var(--big-radius)
  background var(--gray)
  border 1px solid var(--gray-dark)
  display flex
  flex-direction column
  gap 30px
  +below(473px) {
    padding 20px
    gap 15px
  }

  &__content {
    display flex
    flex-direction column
    gap 15px
  }

  &__title {
    font-size 1.125em
    line-height 28px
    +below(473px) {
      font-size 1em
      line-height 26px
    }
  }

  &__subtitle {
    font-size 1.375em
    font-weight 700
    line-height 26px
  }
}
</style>
