<template>
  <div v-if="data && data.length" class="faq">
    <div v-for="(item, i) in data" :key="i" class="faq-item" :class="{ 'faq-item__open': activeFaq === i }">
      <button type="button" class="faq-item__header" @click="toggleFaq(i)">
        <span class="faq-item__title">{{ item.question }}</span>
        <IconComponent name="arrow-up" />
      </button>
      <div class="faq-item__body" v-if="activeFaq === i">
        <span>{{ item.answer }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "FaqComponent",
  components: { IconComponent },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeFaq: null,
    };
  },
  methods: {
    toggleFaq(i) {
      this.activeFaq = this.activeFaq === i ? null : i;
    },
  },
};
</script>

<style lang="stylus">
.faq {
  width 100%
  display flex
  flex-direction column
  gap 20px

  &-item {
    display grid
    align-items start
    border-radius var(--big-radius)
    padding 30px
    grid-gap 20px
    background var(--gray)
    +below(473px) {
      padding 20px
    }

    &__open &__header {

      .icon {
        transform rotate(180deg)
      }
    }

    &__header {
      display flex
      align-items center
      justify-content space-between
      text-align left
      gap 10px
      border none
      background none
      cursor pointer
      font-size 1.375em
      font-weight 700
      line-height 26px
      +below(473px) {
        font-size 1.125em
      }

      .icon {
        width 16px
        height 16px
      }

      &:hover {
        color var(--main)
        transition var(--transition)

        .icon svg {
          path {
            stroke var(--main)
            transition var(--transition)
          }
        }
      }
    }

    &__body {
      font-weight 400
      font-size 1.125em
      line-height 28px
      +below(473px) {
        font-size 0.875em
        line-height 20px
      }
    }
  }
}
</style>
