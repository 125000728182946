<template>
  <div class="page faq-page">
    <BreadcrumbsComponent title="Вопросы и ответы" />
    <div class="page__inner">
      <SectionComponent>
        <div class="faq-page__inner">
          <FaqComponent :data="faqs" />
          <FaqContactsComponent :phone="feedback.phone" :email="feedback.email" />
        </div>
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import FaqComponent from "./components/FaqComponent.vue";
import FaqContactsComponent from "./components/FaqContactsComponent.vue";
import FAQ_PAGE from "@/graphql/pages/FaqPage.graphql";

export default {
  name: "FaqPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: FAQ_PAGE,
      })
      .then(({ data }) => {
        store.state.faq_page = data.faqs || [];
        // дефолтные данные
        store.state.global.categories = data.categories || [];
        store.state.global.feedback = data.feedback || {};
        store.state.global.pages = data.pages || [];
      })
      .catch(() => {});
  },
  components: { FaqContactsComponent, FaqComponent, SectionComponent, BreadcrumbsComponent },
  data() {
    return {};
  },
  computed: {
    faqs() {
      return this.$store.state.faq_page;
    },
    feedback() {
      return this.$store.state.global.feedback;
    },
  },
};
</script>

<style lang="stylus">
.faq-page {
  &__inner {
    display flex
    gap 30px
    +below(800px) {
    flex-direction column
    }
  }
}
</style>
